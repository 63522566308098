// Here you can add other styles
.c-app {
  display: flex;
  flex-direction: row;
  min-height: 80vh;
}

.c-sidebar {
  background: $primary;
}

.c-sidebar .c-sidebar-brand {
  background: none;
}

.modal-lg {
//   margin: 0 0 0 0;
  max-width: 100%;
//   max-height: 100%;
//   height: 100%;
}
