// Variable overrides
$primary-base:  #076598 !default;
$primary-100:   #e9f4fb !default;
$primary-200:   #d2ecfb !default;
$primary-300:   #bcdef4 !default;
$primary-400:   #a5d9f1 !default;
$primary-500:   #8fc3ed !default;
$primary-600:   #78bcea !default;
$primary-700:   #62abe6 !default;
$primary-800:   #4c9ee2 !default;
$primary-900:   #357fdf !default;

$primary-dark:  #054d74 !default;
$primary:       #076598 !default;
$primary-50:    #0a8fd6 !default;
$primary-25:    #ccc7f6 !default;
